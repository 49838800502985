<template>
  <div>
    <p>
      Lorem ipsum dolor sit amet, consectetuer adipiscing elit, sed diam nonummy
      nibh euismod tincidunt ut laoreet dolore magna aliquam erat volutpat. Ut
      wisi enim ad minim veniam, quis nostrud exerci tation ullamcorper suscipit
    </p>
    <br /><br />
    <div class="space-between align-center">
      <div class="align-center flex-1">
        <div class="filter-icon">
          <img src="/img/filter.svg" alt="" />
        </div>

        <div class="search-wrappper mt-2 mb-2 mt-md-0 mb-md-0">
          <b-input-group size="sm">
            <b-form-input v-model="filter" placeholder="Search here...">
            </b-form-input>

            <b-input-group-append>
              <b-button :disabled="!filter" @click="filter = ''">
                Clear
              </b-button>
            </b-input-group-append>
          </b-input-group>
        </div>
      </div>

      <!-- <div class="p-relative">
        <button
          type="button"
          class="btn mulai  font-bold"
          @click="buatUser"
        >
          <fa-icon icon="plus-circle" class="mr-1" /> BUAT USER
        </button>
      </div> -->
    </div>
    <br /><br />

    <!-- Main table element -->
    <b-table
      show-empty
      stacked="md"
      :busy="isBusy"
      :items="items"
      :fields="fields"
      :current-page="currentPage"
      :per-page="perPage"
      :filter="filter"
      :sort-by.sync="sortBy"
      :sort-desc.sync="sortDesc"
      :sort-direction="sortDirection"
      @filtered="onFiltered"
    >
      <template v-slot:cell(actions)="row">
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleVerify(row.item.id, row.item.verified==0 ? 1 : 0)"
          v-if="row.item.verified==0"
        >
          <fa-icon icon="pencil-alt" class="mr-1" />Verify
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleVerify(row.item.id, row.item.verified==0 ? 1 : 0)"
          v-else
        >
          <fa-icon icon="minus-circle" class="mr-1" />Block
        </b-button>
        <b-button
          size="sm"
          class="mr-3 action-button"
          variant="outline-secondary"
          @click="handleRole(row.item)"
        >
          <fa-icon icon="pencil-alt" class="mr-1" /> Role
        </b-button>

        <!-- <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-if="row.item.published"
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="minus-circle" class="mr-1" /> Unpublish
        </b-button>

        <b-button
          size="sm"
          class="mr-3 action-button pub-unpub"
          variant="outline-secondary"
          v-else
          @click="row.item.published = !row.item.published"
        >
          <fa-icon icon="circle" class="mr-1" /> Publish
        </b-button> -->
      </template>
    </b-table>

    <b-row>
      <b-col md="6" class="my-1">
        <b-pagination
          v-model="currentPage"
          :total-rows="totalRows"
          :per-page="perPage"
          class="my-0"
        ></b-pagination>
      </b-col>
    </b-row>

    <b-modal
      v-model="roleModal"
      size="sm"
      title="Role"
      centered
    >
      <label>User Role</label>
      <b-form-checkbox-group
        id="checkbox-group-1"
        v-model="currentRole"
        :options="role"
        name="flavour-1"
      ></b-form-checkbox-group>
   
      <template v-slot:modal-footer>
        <b-button
          variant="primary"
          size="sm"
          class="float-right"
          @click="confirm"
        >
          SAVE
        </b-button>
      </template>
      <br />
      <br />
    </b-modal>

  </div>
</template>

<script>
import { mapActions } from "vuex";
import * as types from "@/store/types";

export default {
  data() {
    return {
      currentRole: [],
      currentId: null,
      isBusy: true,
      selectId: null,
      items: [], 
      fields: [
        { key: "no", label: "No" },
        {
          key: "email",
          label: "Email",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "created_at",
          label: "Created_at",
          sortable: true,
          sortDirection: "desc"
        },
        {
          key: "status",
          label: "Status"
        },
        { key: "actions", label: "Action" }
      ],
      totalRows: 1,
      currentPage: 1,
      perPage: 10,
      sortBy: null,
      sortDesc: false,
      sortDirection: "asc",
      filter: null,
      showPopup: false,
      role:[],
      roleModal:false
    };
  },

  mounted() {
    this.totalRows = this.items.length;
    this.userList();
    this.roleList();
  },

  methods: {
    ...mapActions({
      getUserList: types.GET_USER_LIST,
      getRoleList: types.GET_ROLE_LIST,
      verifyUser: types.VERIFY_USER,
      updateUserRole: types.UPDATE_USER_ROLE
    }),
    confirm() {
      this.updateUserRole({id:this.currentId, role:this.currentRole})
      .then(response => {
        this.roleModal = false;
        this.userList();
      })
      .catch(error => {
        this.toastError('b-toaster-top-center', error);
      })
    },
    handleRole(item) {
      this.currentId=item.id;
      this.currentRole=[];
      for (var i = 0; i < item.role.length; i++) {
        this.currentRole.push(item.role[i].id);
      }
      this.roleModal = true;
    },
    roleList() {
      this.getRoleList()
        .then(response => {
          var res = response;
          this.role = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              value: res[i].id,
              text: res[i].name
            };
            this.role.push(dataItem);
          }
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    userList(level_id = null) {
      this.isBusy = true;
      this.getUserList()
        .then(response => {
          var res = response;
          this.items = [];
          for (var i = 0; i < res.length; i++) {
            var dataItem = {
              no: i + 1,
              id: res[i].id,
              email: res[i].email,
              verified: res[i].verified,
              status: res[i].verified == 1 ? 'Verified' : '',
              created_at: this.formatDate(res[i].created_at),
              role: res[i].role
            };
            this.items.push(dataItem);
          }
          this.totalRows = res.length;
          this.isBusy = false;
        })
        .catch(error => {
          this.toastError('b-toaster-top-center', error);
        });
    },
    handleVerify(id, status) {
      this.$bvModal
      .msgBoxConfirm("Verify user?", {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: false,
        centered: true
      })
      .then(value => {
        if (value) {
          this.verifyUser({status:status, id:id})
          .then(response => {
            this.toastSuccess('b-toaster-top-center');
            this.userList();
          })
          .catch(error => {
            this.toastError('b-toaster-top-center', error);
          })
        }
      })
      .catch(err => {
        this.toastError('b-toaster-top-center', err);
      });
    },
    formatDate(strDate) {
      var date = new Date(strDate);
      var yyyy = date.getFullYear();
      var mm = String(date.getMonth()+1).padStart(2, "0");
      var dd = String(date.getDate()).padStart(2, "0");
      var hh = String(date.getHours()).padStart(2, "0");
      var ii = String(date.getMinutes()).padStart(2, "0");
      var ss = String(date.getSeconds()).padStart(2, "0");

      date = yyyy + "-" + mm + "-" + dd + " " + hh + ":" + ii + ":" + ss;

      return date;
    },
    toastSuccess(toaster, append = false) {
      this.$bvToast.toast('Data updated', {
        title: 'Notification',
        toaster: toaster,
        solid: true,
        variant: 'success',
        autoHideDelay: 1000
      })
    },
    toastError(toaster, error, append = false, ) {
      this.$bvToast.toast(error, {
        title: 'Error',
        toaster: toaster,
        solid: true,
        variant: 'danger',
        autoHideDelay: 1000
      })
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.totalRows = filteredItems.length;
      this.currentPage = 1;
    },
    // buatUser() {
    //   this.$router.push("/user/add");
    // }
  }
};
</script>

<style>
.search-wrapper {
  width: 100%;
  max-width: 222px;
}

.filter-icon {
  margin-right: 11px;
  background-color: #e9e9e9;
  width: 32px;
  height: 32px;
  border-radius: 32px;
  text-align: center;
  padding-top: 7px;
}

.action-button:hover {
  background-color: #f08045;
  border: 1px solid #f08045;
}

.product-popup {
  position: absolute;
  z-index: 99;
  right: 0;
  top: -88px;
  width: 316px;
  height: 70px;
  box-shadow: 0 0 6px 0 rgba(0, 0, 0, 0.16);
  border: solid 1px #f08045;
  border-radius: 4px;
  background-color: #ffffff;
  padding: 4px;
  display: flex;
}

.product-popup__item {
  flex: 1;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 10px;
  font-weight: 500;
  color: #494949;
  flex-direction: column;
}

.product-popup__item:hover {
  background-color: #ffe1d2;
}

.product-popup-arrow {
  position: absolute;
  z-index: 999;
  right: 99px;
  top: -19px;
  width: 14px;
}

.search-wrappper {
  min-width: 160px;
}

.pub-unpub {
  min-width: 105px;
}
</style>
